import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, concatMap, map, retry } from 'rxjs/operators';
import {
  LivErrorResponse,
  LivResponseProtocol,
  LivSuccessResponse
} from 'src/app/core/models/liv-response-protocol.model';
import {
  PortfolioErrorResponse,
  PortfolioResponseProtocol
} from 'src/app/core/models/portfolio-response-protocol.model';
import { shouldRetry } from 'src/app/shared/rxjs/custom-operators';
import { AuthStore } from 'src/app/shared/store/auth.store';
import { environment } from 'src/environments/environment';

import { ToastService } from '../services/toast.service';

const EXCLUDED_STATUS_CODES_ON_RETRY = [
  HttpStatusCode.NotFound,
  HttpStatusCode.Forbidden,
  HttpStatusCode.Unauthorized,
  HttpStatusCode.NotFound,
  HttpStatusCode.UnprocessableEntity
];

const retryConfig = {
  maxRetryAttempts: 1,
  scalingDuration: 3000,
  excludedStatusCodes: EXCLUDED_STATUS_CODES_ON_RETRY
} as const;

@Injectable()
export class ResponseProtocolInterceptor implements HttpInterceptor {
  constructor(
    private authStore: AuthStore,
    private toastService: ToastService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<LivSuccessResponse | LivErrorResponse>> {
    return next.handle(req).pipe(
      concatMap((event) => this.processHttpEvent(event)),
      map((event) => this.handleResponseEvent(event)),
      retry(shouldRetry(retryConfig)),
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  private processHttpEvent(
    event: HttpEvent<unknown>
  ): Promise<HttpEvent<unknown>> {
    if (event instanceof HttpResponse) {
      return this.handleJsonResponseAsBlob(event);
    }
    return Promise.resolve(event);
  }

  private handleResponseEvent(
    event: HttpEvent<unknown>
  ): HttpEvent<LivSuccessResponse | LivErrorResponse> {
    if (event instanceof HttpResponse) {
      return event.clone({ body: this.processResponse(event) });
    }
    return event;
  }

  private processResponse(
    event: HttpResponse<unknown>
  ): LivSuccessResponse | LivErrorResponse {
    const response = event.body as LivResponseProtocol;

    if (
      event.url.includes('assets') ||
      event.url.includes('google') ||
      event.body instanceof Blob
    ) {
      return event.body as never;
    }

    if (event.url.includes(environment.apiPortfolio)) {
      return this.processPortfolioResponse(event);
    }

    if (response.error) {
      throw new HttpErrorResponse({
        status: response.status ?? event.status,
        error: response.error,
        url: event.url
      });
    }

    if ('data' in response) {
      return {
        status: response.status ?? event.status,
        data: response.data,
        meta: response.meta
      };
    }

    throw new HttpErrorResponse({
      status: event.status,
      error: event.body,
      url: event.url
    });
  }

  private processPortfolioResponse(
    event: HttpResponse<unknown>
  ): LivSuccessResponse {
    const response = event.body as PortfolioResponseProtocol;
    return {
      status: event.status,
      data: response.data
    };
  }

  private handleError(response: HttpErrorResponse): Observable<never> {
    if (response.status === HttpStatusCode.InternalServerError) {
      return this.handleServerError(response);
    }

    if (response.status === HttpStatusCode.Unauthorized) {
      return this.handleUnauthorizedError(response);
    }

    return throwError(() => this.createErrorResponse(response));
  }

  private handleServerError(response: HttpErrorResponse): Observable<never> {
    let message = response.error?.message as string;

    if (!message || message.includes('stacktrace')) {
      message = 'Ocorreu um erro inesperado! Tente novamente mais tarde.';
    }

    this.toastService.error(message, {
      id: 'server-error'
    });
    return EMPTY;
  }

  private handleUnauthorizedError(
    response: HttpErrorResponse
  ): Observable<never> {
    const allowedPaths = ['login', 'redefinirsenha'];
    const isNotAllowed = !allowedPaths.some((path) =>
      response.url?.toLocaleLowerCase().includes(path)
    );

    if (!isNotAllowed) {
      return throwError(() => this.createErrorResponse(response));
    }

    if (this.authStore.isAuthenticated) {
      this.authStore.logout();

      this.toastService.error('Sessão expirada! Faça login novamente.', {
        id: 'session-expired'
      });
    }
    return EMPTY;
  }

  private createErrorResponse(response: HttpErrorResponse): LivErrorResponse {
    const errorResponse = {} as LivErrorResponse;

    if (response.url.includes(environment.apiPortfolio)) {
      const portfolioErrorResponse: PortfolioErrorResponse = response.error;

      Object.assign(errorResponse, {
        status: portfolioErrorResponse.status,
        error: {
          message: portfolioErrorResponse.error.message,
          stack: {
            url: response.url,
            ...portfolioErrorResponse.error.details
          }
        }
      });
    } else {
      Object.assign(errorResponse, {
        status: response.status,
        error: {
          ...response.error,
          stack: {
            url: response.url
          }
        }
      });
    }

    return errorResponse;
  }

  private async handleJsonResponseAsBlob(event: HttpResponse<any>) {
    if (event.body instanceof Blob && event.body.type === 'application/json') {
      const content = await event.body.text();
      return event.clone({ body: JSON.parse(content) });
    }
    return event;
  }
}
